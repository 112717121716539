import Grid from "@material-ui/core/Grid";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaYoutubeSquare } from "react-icons/fa";
import { FaAppleAlt } from "react-icons/fa";

export default function Home() {
  const { t } = useTranslation();

  return (
    <div style={{ marginTop: "70px" }}>
      <React.Fragment>
        <h1 style={{ backgroundColor: "#f9edff" }}> {t("login.1")}</h1>
        <h3> {t("login.2")}</h3>
        <h4>{t("login.3")}</h4>
        <Grid>
          <a href="/Videa">
            <button
              style={{
                margin: "50px",
                border: "bold",
                backgroundColor: "#e1d1eb",
                alignItems: "center",
                width: "45%",
                height: "70px",
              }}
            >
              <FaYoutubeSquare size="30px" color="purple" /> &nbsp; &nbsp;
              &nbsp;
              <b>{t("login.4")}</b>
            </button>
          </a>
        </Grid>
        <Grid>
          <a href="/recepti">
            <button
              style={{
                margin: "50px",
                border: "bold",
                backgroundColor: "#e1d1eb",
                alignItems: "center",
                width: "45%",
                height: "70px",
              }}
            >
              <FaAppleAlt size="30px" color="purple" /> &nbsp; &nbsp; &nbsp;{" "}
              <b> {t("login.5")}</b>
            </button>
          </a>
        </Grid>
      </React.Fragment>
    </div>
  );
}
