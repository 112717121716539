import React from "react";
import jump from "../sliki.png/jump.jpg";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "react-bootstrap";
import { GrLinkNext } from "react-icons/gr";
import Instruktor from "../Instruktor.js";
import ana3 from "../sliki.png/ana3.png";
import ShareButton from "./ShareButton.js";
import { useTranslation } from "react-i18next";
import { GrLinkPrevious } from "react-icons/gr";
import { Helmet } from "react-helmet";
const useStyles = makeStyles({
  photo: {
    maxWidth: "100%",
    maxHeight: "100% ",
  },
  media: {
    height: 400,
  },
});
export default function Blog18() {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div
      style={{
        marginTop: "80px",
        textAlign: "left",
        fontStyle: "initial",
      }}
    >
      <Helmet>
        <title>{t("blog18.1")}</title>
        <meta name="description" content={t("blog18.5")} />
        <link rel="canonical" href="https://anafit.mk/blog18" />
        <meta
          name="og:image"
          content="	https://anafit.mk/static/media/paddle.4a093ad2.webp"
        />
        <meta property="og:url" content="https://anafit.mk/blog18" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="142394897894893" />
        <meta property="og:title" content={t("blog18.1")} />
        <meta property="og:description" content={t("blog18.5")} />
      </Helmet>
      <React.Fragment>
        <Container maxWidth="sm" style={{ backgroundColor: "#fff0fa" }}>
          <h1>{t("blog18.1")}</h1>

          <img className={classes.photo} src={jump} alt="food" />

          <p>{t("blog18.2")}</p>
          <p>{t("blog18.3")}</p>

          <p>{t("blog18.4")}</p>
          <p>{t("blog18.5")}</p>
          <p>
            <b>{t("blog18.6")} </b>
          </p>
          <p>{t("blog18.7")}</p>
          <p>{t("blog18.8")}</p>
          <p>{t("blog18.9")}</p>
          <p>
            <b>{t("blog18.10")}</b>
          </p>
          <p>{t("blog18.11")}</p>
          <p>
            <b>{t("blog18.12")}</b>
          </p>
          <p>{t("blog18.13")}</p>
          <p>{t("blog18.14")}</p>
          <p>
            <b>{t("blog18.15")}</b>
          </p>
          <p>{t("blog18.16")}</p>
          <p>{t("blog18.17")}</p>
          <p>
            <b>{t("blog18.18")}</b>
          </p>
          <p>{t("blog18.19")}</p>
          <p>
            <b>{t("blog18.20")}</b>
          </p>
          <p>{t("blog18.21")}</p>
          <p>
            <b>{t("blog18.22")}</b>
          </p>
          <p>{t("blog18.23")}</p>
          <p>
            <b>{t("blog18.24")}</b>
          </p>
          <p>{t("blog18.25")}</p>
          <p>{t("blog18.26")}</p>
          <p>{t("blog18.27")}</p>
        </Container>
      </React.Fragment>

      <div
        style={{
          textAlign: "center",
          paddingTop: "50px",
          paddingBottom: "30px",
          backgroundColor: "#f9f5fc",
        }}
      >
        {" "}
        <ShareButton url="https://anafit.mk/blog18" />
        <Button
          href="https://anafit.mk/blog17"
          style={{
            border: "3px",
            fontStyle: "-moz-initial",
            fontSize: "22px",

            textDecoration: "none",
          }}
        >
          {" "}
          <GrLinkPrevious /> &nbsp;{t("blog17.1")}
        </Button>{" "}
        <Button
          href="/blog19"
          style={{
            border: "3px",
            fontStyle: "-moz-initial",
            fontSize: "22px",
            textDecoration: "none",
          }}
        >
          {" "}
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{t("blog19.1")}&nbsp;
          <GrLinkNext />{" "}
        </Button>
      </div>

      <div style={{ textAlign: "center" }}>
        <h1>{t("autor")}</h1>
        <Instruktor
          url="https://www.instagram.com/anafit.mk/"
          image={ana3}
          title={t("aboutUs.3")}
          description={t("aboutUs.4")}
        />
      </div>
    </div>
  );
}
