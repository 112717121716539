import firebase from "firebase/app";
import "firebase/auth";

const app = firebase.initializeApp({
  apiKey: "AIzaSyCdYbin3e_l73vPLT4nDOfGTpbB-TpSfVo",
  authDomain: "anafit-71187.firebaseapp.com",
  projectId: "anafit-71187",
  storageBucket: "anafit-71187.appspot.com",
  messagingSenderId: "315738199504",
  appId: "1:315738199504:web:811ebf2a47eb535d9fd5d5",
});

export const auth = app.auth();
export default app;
