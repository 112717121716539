import React from "react";
import { BrowserRouter as Switch, Route } from "react-router-dom";
import "./App.css";
import PrivateRoute from "./log/PrivateRoute";
import { AuthProvider } from "./log/contexts/AuthContext";
import { BrowserRouter as Router, Redirect } from "react-router-dom";
import Blog18 from "./components/blog/Blog18";
const Glavno = React.lazy(() => import("./components/Home/Glavno"));
// const reportWebVitals = React.lazy(() => import("./reportWebVitals"));
const Header = React.lazy(() => import("./components/Header"));
const Log = React.lazy(() => import("./components/najaviSe"));
const Butt = React.lazy(() => import("./components/Home/Butt"));
const Aerobik = React.lazy(() => import("./components/Home/Aerobik"));
const Privatni = React.lazy(() => import("./components/Home/Privatni"));
const Bremeni = React.lazy(() => import("./components/Home/Bremeni"));
const Funkcionalen = React.lazy(() => import("./components/Home/Funkcionalen"));
const Pilates = React.lazy(() => import("./components/Home/Pilates"));
const Abs = React.lazy(() => import("./components/Home/Abs"));
const Recepti3 = React.lazy(() => import("./components/Recipes/Recepti3"));
const Pojadok = React.lazy(() => import("./components/Recipes/Pojadok"));
const Granola = React.lazy(() => import("./View/Granola"));
const Chia = React.lazy(() => import("./components/Recipes/Chia"));
const Smuti = React.lazy(() => import("./View/Smuti"));
const Banana = React.lazy(() => import("./View/Banana"));
const Zeleno = React.lazy(() => import("./View/Zeleno"));
const Aronija = React.lazy(() => import("./View/Aronija"));
const Rucek3 = React.lazy(() => import("./components/Recipes/Rucek3"));
const Losos = React.lazy(() => import("./View/Losos"));
const Domati = React.lazy(() => import("./View/Domati"));
const Sarma = React.lazy(() => import("./View/Sarma"));
const Vege = React.lazy(() => import("./View/Vege"));
const Lekja = React.lazy(() => import("./View/Lekja"));
const Boranija = React.lazy(() => import("./View/Boranija"));
const Shpagetiavokado = React.lazy(() => import("./View/Shpagetiavokado"));
const Tost = React.lazy(() => import("./View/Tost"));
const Bamja = React.lazy(() => import("./View/Bamja"));
const Grashak = React.lazy(() => import("./View/Grashak"));
const Tavavege = React.lazy(() => import("./View/Tavavege"));
const Karfiol = React.lazy(() => import("./View/Karfiol"));
const Biftek = React.lazy(() => import("./View/Biftek"));
const Kopani = React.lazy(() => import("./View/Kopani"));
const Kajgana = React.lazy(() => import("./View/Kajgana"));
const Spanakj = React.lazy(() => import("./View/Spanakj"));
const Vecera3 = React.lazy(() => import("./components/Recipes/Vecera3"));
const Cezar = React.lazy(() => import("./View/Cezar"));
const Batatsalata = React.lazy(() => import("./View/Batatsalata"));
const Losossalata = React.lazy(() => import("./View/Losossalata"));
const ChiaTikva = React.lazy(() => import("./View/ChiaTikva"));
const Zanas = React.lazy(() => import("./components/AboutUs/Zanas"));
const Blog = React.lazy(() => import("./components/blog/Blog"));
const Blog1 = React.lazy(() => import("./components/blog/Blog1"));
const Blog2 = React.lazy(() => import("./components/blog/Blog2"));
const Blog3 = React.lazy(() => import("./components/blog/Blog3"));
const Blog4 = React.lazy(() => import("./components/blog/Blog4"));
const Blog5 = React.lazy(() => import("./components/blog/Blog5"));
const Blog6 = React.lazy(() => import("./components/blog/Blog6"));
const Blog7 = React.lazy(() => import("./components/blog/Blog7"));
const Blog8 = React.lazy(() => import("./components/blog/Blog8"));
const Blog9 = React.lazy(() => import("./components/blog/Blog9"));
const Blog10 = React.lazy(() => import("./components/blog/Blog10"));
const Blog20 = React.lazy(() => import("./components/blog/Blog20"));
const Blog11 = React.lazy(() => import("./components/blog/Blog11"));
const Blog12 = React.lazy(() => import("./components/blog/Blog12"));
const Blog13 = React.lazy(() => import("./components/blog/Blog13"));
const Blog15 = React.lazy(() => import("./components/blog/Blog15"));
const Video1 = React.lazy(() => import("./components/video/Video1"));
const Footer = React.lazy(() => import("./Footer"));
const Faq = React.lazy(() => import("./View/Faq"));
const Video2 = React.lazy(() => import("./components/video/Video2"));
const Video3 = React.lazy(() => import("./components/video/Video3"));
const Video4 = React.lazy(() => import("./components/video/Video4"));
const Video5 = React.lazy(() => import("./components/video/Video5"));
const Video6 = React.lazy(() => import("./components/video/Video6"));
const Video7 = React.lazy(() => import("./components/video/Video7"));
const Video8 = React.lazy(() => import("./components/video/Video8"));
const Video9 = React.lazy(() => import("./components/video/Video9"));
const Video10 = React.lazy(() => import("./components/video/Video10"));
const Jagoda = React.lazy(() => import("./View/Jagoda"));
const Video11 = React.lazy(() => import("./components/video/Video11"));
const Video12 = React.lazy(() => import("./components/video/Video12"));
const Video13 = React.lazy(() => import("./components/video/Video13"));
const Video14 = React.lazy(() => import("./components/video/Video14"));
const VideaGrid = React.lazy(() => import("./components/video/VideaGrid"));
const TikvaGranola = React.lazy(() => import("./View/TikvaGranola"));
const TikvaKinoa = React.lazy(() => import("./View/TikvaKinoa"));
const TikvaMed = React.lazy(() => import("./View/TikvaMed"));
const TikvaPuter = React.lazy(() => import("./View/TikvaPuter"));
const OvesBanana = React.lazy(() => import("./View/OvesBanana"));
const OvesMalini = React.lazy(() => import("./View/OvesMalini"));
const ChiaChoko = React.lazy(() => import("./View/ChiaChoko"));
const Gjumbir = React.lazy(() => import("./View/Gjumbir"));
const Borovinka = React.lazy(() => import("./View/Borovinka"));
const Smuti2 = React.lazy(() => import("./View/Smuti2"));
const Avocado = React.lazy(() => import("./View/Avocado"));
const Oat = React.lazy(() => import("./View/Oat"));
const Dzejmi = React.lazy(() => import("./View/Dzejmi"));
const Apple = React.lazy(() => import("./View/Apple"));
const Carrote = React.lazy(() => import("./View/Carrote"));
const Kiwi = React.lazy(() => import("./View/Kiwi"));
const OatAronija = React.lazy(() => import("./View/OatAronija"));
const Chia1 = React.lazy(() => import("./components/Recipes/Chia"));
const Chia3 = React.lazy(() => import("./components/Recipes/Chia3"));
const ChiaPeanut = React.lazy(() => import("./View/ChiaPeanut"));
const Mushrooms = React.lazy(() => import("./View/Mushrooms"));
const Cacao = React.lazy(() => import("./View/Cacao"));
const Urda = React.lazy(() => import("./View/Urda"));
const Steak = React.lazy(() => import("./View/Steak"));
const Shells = React.lazy(() => import("./View/Shells"));
const Burito = React.lazy(() => import("./View/Burito"));
const Asparagus = React.lazy(() => import("./View/Asparagus"));
const Trout = React.lazy(() => import("./View/Trout"));
const Pizza = React.lazy(() => import("./View/Pizza"));
const Pumpkin = React.lazy(() => import("./View/Pumpkin"));
const Tuna = React.lazy(() => import("./View/Tuna"));
const Salomon = React.lazy(() => import("./View/Salomon"));
const Shopska = React.lazy(() => import("./View/Shopska"));
const SupaBrokula = React.lazy(() => import("./View/SupaBrokula"));
const SupaProkelj = React.lazy(() => import("./View/SupaProkelj"));
const Tomato = React.lazy(() => import("./View/Tomato"));
const SupaBamja = React.lazy(() => import("./View/SupaBamja"));
const SupaSpanak = React.lazy(() => import("./View/SupaSpanak"));
const LadnaSupa = React.lazy(() => import("./View/LadnaSupa"));
const Search = React.lazy(() => import("./components/Recipes/Search"));
const Vegeterian = React.lazy(() => import("./components/Recipes/Vegeterian"));
const Blog14 = React.lazy(() => import("./components/blog/Blog14"));
const Blog19 = React.lazy(() => import("./components/blog/Blog19.js"));

const RecipesFirst = React.lazy(() =>
  import("./components/Recipes/RecipesFirst")
);
const VideoFirst = React.lazy(() => import("./components/video/VideoFirst"));
const Home = React.lazy(() => import("./login/Home"));
const Blog21 = React.lazy(() => import("./components/blog/Blog21.js"));
const Blog16 = React.lazy(() => import("./components/blog/Blog16"));
const Blog17 = React.lazy(() => import("./components/blog/Blog17"));

export default function App() {
  return (
    <div className="App">
      <React.Suspense fallback={<p>.... Loading</p>}>
        <Header />
        <Switch>
          <Route exact path="/">
            <Redirect to="/Glavno" />
          </Route>
          <Route path="/Glavno">
            <Glavno />
          </Route>

          <Route path="/videoFirst">
            <VideoFirst />
          </Route>
          <Route path="/search">
            <Search />
          </Route>

          <Route path="/vegeterian">
            <Vegeterian />
          </Route>
          <Route path="/welcome">
            <Home />
          </Route>
          <Route path="/supaTomato">
            <Tomato />
          </Route>
          <Route path="/pumpkin">
            <Pumpkin />
          </Route>
          <Route path="/blog">
            <Blog />
          </Route>
          <Route path="/ovesAronija">
            <OatAronija />
          </Route>

          <Route path="/login">
            <Log />
          </Route>

          <Route path="/mushrooms">
            <Mushrooms />
          </Route>
          <Route path="/supaProkelj">
            <SupaProkelj />
          </Route>
          <Route path="/steak">
            <Steak />
          </Route>
          <Route path="/supaBamja">
            <SupaBamja />
          </Route>
          {/* <Route path="/recepti">
          <Recepti3 />
        </Route> */}
          <Route path="/RecipesFirst">
            <RecipesFirst />
          </Route>

          <Route path="/supaBrokula">
            <SupaBrokula />
          </Route>
          <Route path="/tuna">
            <Tuna />
          </Route>
          <Route path="/ladnaSupa">
            <LadnaSupa />
          </Route>
          <Route path="/supaSpanak">
            <SupaSpanak />
          </Route>
          <Route path="/gaze">
            <Butt />
          </Route>
          <Route path="/shopska">
            <Shopska />
          </Route>
          <Route path="/aerobik">
            <Aerobik />
          </Route>
          <Route path="/urda">
            <Urda />
          </Route>
          <Route path="/salomon">
            <Salomon />
          </Route>
          <Route path="/shells">
            <Shells />
          </Route>
          <Route path="/blog20">
            <Blog20 />
          </Route>
          <Route path="/privatni">
            <Privatni />
          </Route>
          <Route path="/cacao">
            <Cacao />
          </Route>
          <Route path="/bremeni">
            <Bremeni />
          </Route>
          <Route path="/funkcionalen">
            <Funkcionalen />
          </Route>
          <Route path="/pilates">
            <Pilates />
          </Route>
          <Route path="/abs">
            <Abs />
          </Route>
          <Route path="/zanas">
            <Zanas />
          </Route>
          <Route path="/pojadok">
            <Pojadok />
          </Route>
          <Route path="/rucek">
            <Rucek3 />
          </Route>
          <Route path="/vecera">
            <Vecera3 />
          </Route>
          <Route path="/granola">
            <Granola />
          </Route>
          <Route path="/chia">
            <Chia />
          </Route>
          <Route path="/chia3">
            <Chia3 />
          </Route>
          <Route path="/smuti">
            <Smuti />
          </Route>
          <Route path="/asparagus">
            <Asparagus />
          </Route>
          <Route path="/burito">
            <Burito />
          </Route>
          <Route path="/zeleno">
            <Zeleno />
          </Route>
          <Route path="/banana">
            <Banana />
          </Route>
          <Route path="/aronija">
            <Aronija />
          </Route>
          <Route path="/aronija">
            <Aronija />
          </Route>
          <Route path="/losos">
            <Losos />
          </Route>
          <Route path="/sarma">
            <Sarma />
          </Route>
          <Route path="/domati">
            <Domati />
          </Route>
          <Route path="/svadbarska">
            <Vege />
          </Route>
          <Route path="/lekja">
            <Lekja />
          </Route>
          <Route path="/boranija">
            <Boranija />
          </Route>
          <Route path="/shpagetiavokado">
            <Shpagetiavokado />
          </Route>
          <Route path="/trout">
            <Trout />
          </Route>
          <Route path="/pizza">
            <Pizza />
          </Route>
          <Route path="/chiaPeanut">
            <ChiaPeanut />
          </Route>
          <Route path="/carrote">
            <Carrote />
          </Route>
          <Route path="/tost">
            <Tost />
          </Route>
          <Route path="/bamja">
            <Bamja />
          </Route>
          <Route path="/apple">
            <Apple />
          </Route>
          <Route path="/tikvaGranola">
            <TikvaGranola />
          </Route>
          <Route path="/smutiOves">
            <Oat />
          </Route>
          <Route path="/dzejmi">
            <Dzejmi />
          </Route>
          <Route path="/avocado">
            <Avocado />
          </Route>
          <Route path="/grashak">
            <Grashak />
          </Route>
          <Route path="/tikvaKinoa">
            <TikvaKinoa />
          </Route>
          <Route path="/kiwi">
            <Kiwi />
          </Route>
          <Route path="/smuti2">
            <Smuti2 />
          </Route>
          <Route path="/borovinka">
            <Borovinka />
          </Route>
          <Route path="/tavavege">
            <Tavavege />
          </Route>
          <Route path="/karfiol">
            <Karfiol />
          </Route>
          <Route path="/chiaChoko">
            <ChiaChoko />
          </Route>
          <Route path="/gjumbir">
            <Gjumbir />
          </Route>
          <Route path="/jagoda">
            <Jagoda />
          </Route>
          <Route path="/biftek">
            <Biftek />
          </Route>
          <Route path="/kopani">
            <Kopani />
          </Route>
          <Route path="/kajgana">
            <Kajgana />
          </Route>
          <Route path="/spanakj">
            <Spanakj />
          </Route>
          <Route path="/tikvaPuter">
            <TikvaPuter />
          </Route>
          <Route path="/oves">
            <OvesBanana />
          </Route>
          <Route path="/ovesMalini">
            <OvesMalini />
          </Route>
          <Route path="/cezar">
            <Cezar />
          </Route>
          <Route path="/batatsalata">
            <Batatsalata />
          </Route>
          <Route path="/TikvaMed">
            <TikvaMed />
          </Route>
          <Route path="/losossalata">
            <Losossalata />
          </Route>
          <Route path="/chiaTikva">
            <ChiaTikva />
          </Route>
          <Route path="/chia1">
            <Chia1 />
          </Route>
          <Route path="/blog1">
            <Blog1 />
          </Route>
          <Route path="/blog2">
            <Blog2 />
          </Route>
          <Route path="/blog3">
            <Blog3 />
          </Route>
          <Route path="/blog4">
            <Blog4 />
          </Route>
          <Route path="/blog12">
            <Blog12 />
          </Route>
          <Route path="/blog17">
            <Blog17 />
          </Route>
          <Route path="/blog5">
            <Blog5 />
          </Route>
          <Route path="/blog15">
            <Blog15 />
          </Route>
          <Route path="/video1">
            <Video1 />
          </Route>
          <Route path="/video2">
            <Video2 />
          </Route>
          <Route path="/video3">
            <Video3 />
          </Route>
          <Route path="/video4">
            <Video4 />{" "}
          </Route>
          <Route path="/blog6">
            <Blog6 />
          </Route>
          <Route path="/blog11">
            <Blog11 />
          </Route>
          <Route path="/blog8">
            <Blog8 />
          </Route>
          <Route path="/blog16">
            <Blog16 />
          </Route>
          <Route path="/blog9">
            <Blog9 />
          </Route>
          <Route path="/blog10">
            <Blog10 />
          </Route>
          <Route path="/blog18">
            <Blog18 />
          </Route>
          <Route path="/blog19">
            <Blog19 />
          </Route>

          <Route path="/blog7">
            <Blog7 />
          </Route>
          <Route path="/blog13">
            <Blog13 />
          </Route>
          <Route path="/blog14">
            <Blog14 />
          </Route>
          <Route path="/blog21">
            <Blog21 />
          </Route>
          <Route path="/video1">
            <Video1 />
          </Route>
          <Route path="/faq">
            <Faq />
          </Route>
          <Route path="/video5">
            <Video5 />
          </Route>
          <Route path="/video6">
            <Video6 />
          </Route>
          <Route path="/video7">
            <Video7 />
          </Route>
          <Route path="/video8">
            <Video8 />
          </Route>
          <Route path="/video9">
            <Video9 />
          </Route>
          <Route path="/video10">
            <Video10 />
          </Route>
          <Route path="/video11">
            <Video11 />
          </Route>
          <Route path="/video12">
            <Video12 />
          </Route>
          <Route path="/video13">
            <Video13 />
          </Route>
          <Route path="/video14">
            <Video14 />
          </Route>

          <Router>
            <AuthProvider>
              <Switch>
                <PrivateRoute path="/recepti">
                  <Recepti3 />
                </PrivateRoute>
                <PrivateRoute path="/videa">
                  <VideaGrid />
                </PrivateRoute>
              </Switch>
            </AuthProvider>
          </Router>
        </Switch>
      </React.Suspense>
      <Footer />
    </div>
  );
}
