import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Column, FooterLink } from "../components/FooterStyles.js";
import { FaFacebookF } from "react-icons/fa";
import { FiInstagram } from "react-icons/fi";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles({
  root: {
    width: "750px",
    display: "inline-block",
    fontSize: "10px",
    marginTop: "60px",
    border: "20px",
    margin: "20px",
    textAlign: "center",
    maxWidth: "100%",
    maxHeight: "100% ",
    boxShadow: "none",
  },

  media: {
    height: 350,
    width: "250px",
  },
});

export default function Instruktor(props) {
  const classes = useStyles();
  return (
    <Card
      onClick={() => (window.location.href = props.url)}
      className={classes.root}
      style={{ height: "650px" }}
    >
      <CardActionArea>
        <CardMedia
          style={{
            align: "centar",

            fontSize: "14px",
          }}
          className={classes.media}
          image={props.image}
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {props.title}
          </Typography>
          <Typography variant="body2" color="purple" component="p">
            {props.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
