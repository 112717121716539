import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import "./i18next";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

ReactDOM.render(
  <Router>
    <Suspense
      fallback={
        <div style={{ margin: "200px", color: "purple" }}>
          <Loader
            type="Puff"
            color="purple"
            height={100}
            width={100}
            timeout={3000} //3 secs
          />{" "}
          ....Loading{" "}
        </div>
      }
    >
      <App />
    </Suspense>
  </Router>,
  document.getElementById("root")
);

reportWebVitals();
