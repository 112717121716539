import {
  FacebookMessengerIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  TwitterShareButton,
  FacebookShareButton,
} from "react-share";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles({
  ShareButton: {
    width: "35px",
    padding: "5px",
  },
});

export default function ShareButton(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div>
      <FacebookShareButton url={props.url} className={classes.ShareButton}>
        <p style={{ color: "black" }}>
          {t("share")} <FacebookIcon className={classes.ShareButton} />
        </p>
      </FacebookShareButton>

      {/* <TwitterShareButton url={props.url} className={classes.ShareButton}>
        <TwitterIcon className={classes.ShareButton} />{" "}
      </TwitterShareButton> */}
    </div>
  );
}
